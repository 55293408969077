/* eslint-disable no-console */

import { register } from 'register-service-worker'
import swal from 'sweetalert2';
import Cookies from 'js-cookie';

let locale = Cookies.get('locale');
let localeTitle = "New version is available, updating now...";
let localeButton = "OK";
switch (locale) {
  case "is-IS":
    localeTitle = "Ný útgáfa tilbúinn, uppfærist núna...";
    localeButton = "OK";
    break;
  case "pl-PL":
    localeTitle = "Dostępna jest nowa wersja, aktualizacja teraz...";
    localeButton = "OK";
    break;
  case "lt-LT":
    localeTitle = "Nauja versija yra prieinama, atnaujinama dabar...";
    localeButton = "OK";
    break;
}

if (process.env.VUE_APP_NODE_ENV === 'development' || 
    process.env.VUE_APP_NODE_ENV === 'aws-development' || 
    process.env.VUE_APP_NODE_ENV === 'aws-production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL,
    },
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
      swal.fire({
        title: 'Updating...',
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        }
      });
    },
    updated(registration) {
      swal.close(); // Close the loading popup
      console.log('New content is available; updating now.');
      console.log('Registration waiting state:', registration.waiting); // Debug log
      // Show notification that update is happening
      swal.fire({
        title: localeTitle,
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
        },
        confirmButtonText: localeButton,
        buttonsStyling: false,
        timer: 5000, // Auto-close after 5 seconds
        timerProgressBar: true
      }).then(() => {
        // This runs after user clicks OK or timer expires
        try {
          console.log('Reloading page');
          window.location.reload(true); // Just reload, no need for SKIP_WAITING
        } catch (error) {
          console.error('Error during reload:', error);
          swal.fire('Error', 'Update failed, please refresh manually', 'error');
        }
      });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}