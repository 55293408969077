import Cookies from 'js-cookie';
import api from '../../services/api';
import { jwtDecode } from "jwt-decode";

export function loadCustomerConfigs() {
  const token = Cookies.get('accessToken');
  const decodedToken = jwtDecode(token);
  let customerId = decodedToken.CustomerId;
  let url = "Customers/CustomerConfigurations";
  return api.get(url)
    .then(response => {
      let returnData = JSON.stringify(response.data);  // Stringify the object
      Cookies.set(customerId + '-' + 'CustomerConfig', returnData, { expires: 0.25 }); // 0.25 days is 6 hours
      return response.data;
    })
    .catch(error => {
      console.error('API request error:', error);
      return null;
    });
}

export async function getCustomerConfig(configType) {
  const token = Cookies.get('accessToken');
  const decodedToken = jwtDecode(token);
  let customerId = decodedToken.CustomerId;
  let configs;

  if (!Cookies.get(customerId + '-' + 'CustomerConfig')) {
    configs = await loadCustomerConfigs(); // Await the loading function
  } else {
    let storedConfigs = Cookies.get(customerId + '-' + 'CustomerConfig');
    configs = JSON.parse(storedConfigs);
  }

  if (configs) {
    let config = ''
    if (configs.filter(c => c.customerConfigurationType.type === configType) && configs.filter(c => c.customerConfigurationType.type === configType).length > 0)
    {
      config = configs.filter(c => c.customerConfigurationType.type === configType)[0].value;
    }
    else
    {
      return ''
    }

    if (!isNaN(config))
    {
      return Number(config);
    }
    else if (config === "True" || config === "true")
    {
      return true;
    }
    else if (config === "False" || config === "false")
    {
      return false;
    }
    else
    {
      return config;
    }
  }
  else
  {
    console.error('Unable to load customer configs')
    return '';
  }
}

export function getCustomerConfigTypeId(configType) {
  switch (configType) {
    case "EnableCustomQRBanner":
      return 1
    case "EnableProjectCreation":
      return 2
    case "ShowToolId":
      return 3
    case "TimonUsername":
      return 4
    case "TimonPassword":
      return 5
    case "TimonUrl":
      return 6
    case "DKApiKey":
      return 7
    case "DKWebhookSecret":
      return 8
    case "DefaultPageSize":
      return 9
    case "StolpiUsername":
      return 10
    case "StolpiPassword":
      return 11
    case "DisableCars":
      return 12
    case "AllowAllUsersToReturn":
      return 13
    default:
      return 0
  }
}
